var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":_vm.closeoncontentclick,"nudge-right":40,"offset-y":"","min-width":"390px","max-height":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-select',_vm._g(_vm._b({attrs:{"label":_vm.label,"items":_vm.items,"item-value":_vm.item_key,"item-text":_vm.item_text,"rules":[
        function (v) { return _vm.selected_items.length > 0 || !_vm.oblig || _vm.label + ' required'; } ],"multiple":"","menu-props":{ value: false },"clearable":_vm.clearable,"readonly":_vm.readonly,"dense":_vm.dense},on:{"click":function($event){_vm.catpicker1 = true},"change":_vm.s_change},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
      var item = ref.item;
      var index = ref.index;
return [(index <= _vm.nbr_chips - 1)?_c('v-chip',{attrs:{"color":item[_vm.item_color],"close":_vm.deletable_chips,"small":""},on:{"click:close":function($event){return _vm.delchips(item)}}},[_c('span',[_vm._v(_vm._s(item[_vm.item_text]))])]):_vm._e(),(index === _vm.nbr_chips)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.selected_items.length - _vm.nbr_chips)+" others) ")]):_vm._e()]}}],null,true),model:{value:(_vm.selected_items),callback:function ($$v) {_vm.selected_items=$$v},expression:"selected_items"}},'v-select',attrs,false),on))]}}]),model:{value:(_vm.catpicker1),callback:function ($$v) {_vm.catpicker1=$$v},expression:"catpicker1"}},[_c('v-flex',{staticStyle:{"overflow":"auto"},attrs:{"xs12":""}},[_c('v-card',[_c('v-treeview',{attrs:{"items":_vm.treelist,"item-key":_vm.item_key,"item-text":_vm.item_text,"item-disabled":_vm.item_disabled,"color":"primary","transition":"","dense":"","selectable":_vm.selectable,"activatable":!_vm.selectable,"selected-color":_vm.select_item_color,"selection-type":_vm.select_type,"multiple-active":_vm.selectable,"return-object":"","open-all":_vm.open_all},on:{"update:active":_vm.getActiveValue,"input":_vm.getSelectedValue},scopedSlots:_vm._u([{key:"label",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{attrs:{"color":item[_vm.item_color],"small":""}},[_vm._v(" "+_vm._s(item.prop_name ? item.prop_name + ":" : "")+" "+_vm._s(item[_vm.item_text])+" ")])]}}]),model:{value:(_vm.selected_tree),callback:function ($$v) {_vm.selected_tree=$$v},expression:"selected_tree"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }