<template>
  <v-menu
    v-model="catpicker1"
    :close-on-content-click="closeoncontentclick"
    :nudge-right="40"
    offset-y
    min-width="390px"
    max-height="300px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-select
        v-model="selected_items"
        :label="label"
        :items="items"
        :item-value="item_key"
        :item-text="item_text"
        :rules="[
          (v) => selected_items.length > 0 || !oblig || label + ' required',
        ]"
        v-bind="attrs"
        v-on="on"
        multiple
        @click="catpicker1 = true"
        :menu-props="{ value: false }"
        :clearable="clearable"
        :readonly="readonly"
        :dense="dense"
        @change="s_change"
      >
        <template v-slot:selection="{ item, index }">
          <v-chip
            v-if="index <= nbr_chips - 1"
            :color="item[item_color]"
            :close="deletable_chips"
            @click:close="delchips(item)"
            small
          >
            <span>{{ item[item_text] }}</span>
          </v-chip>
          <span v-if="index === nbr_chips" class="grey--text text-caption">
            (+{{ selected_items.length - nbr_chips }} others)
          </span>
        </template>
      </v-select>
    </template>
    <v-flex xs12 style="overflow: auto">
      <v-card>
        <v-treeview
          v-model="selected_tree"
          :items="treelist"
          :item-key="item_key"
          :item-text="item_text"
          :item-disabled="item_disabled"
          color="primary"
          transition
          dense
          :selectable="selectable"
          :activatable="!selectable"
          :selected-color="select_item_color"
          :selection-type="select_type"
          :multiple-active="selectable"
          @update:active="getActiveValue"
          @input="getSelectedValue"
          return-object
          :open-all="open_all"
        >
          <template v-slot:label="{ item }">
            <v-chip :color="item[item_color]" small>
              {{ item.prop_name ? item.prop_name + ":" : "" }}
              {{ item[item_text] }}
            </v-chip>
          </template>
        </v-treeview>
      </v-card>
    </v-flex>
  </v-menu>
</template>

<script>
function list_to_tree(list, p) {
  var map = {},
    node,
    roots = [],
    i;
  if (list) {
    for (i = 0; i < list.length; i += 1) {
      map[list[i].id] = i; // initialize the map
      list[i].children = []; // initialize the children
    }

    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      node.index = i;
      if (node.fk_parent !== p) {
        // if you have dangling branches check that map[node.parentId] exists
        if (list[map[node.fk_parent]])
          list[map[node.fk_parent]].children.push(node);
      } else {
        roots.push(node);
      }
    }
  }
  return roots;
}
Array.prototype.remove = function () {
  var what,
    a = arguments,
    L = a.length,
    ax;
  while (L && this.length) {
    what = a[--L];
    while ((ax = this.indexOf(what)) !== -1) {
      this.splice(ax, 1);
    }
  }

  return this;
};
function compare(a, b) {
  if (a.fk_parent < b.fk_parent) {
    return -1;
  }
  if (a.fk_parent > b.fk_parent) {
    return 1;
  }
  if (a.fk_parent == b.fk_parent) {
    if (a.id < b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
    return 0;
  }
}
function comparer(otherArray) {
  return function (current) {
    return (
      otherArray.filter(function (other) {
        return other.id == current.id;
      }).length == 0
    );
  };
}

export default {
  name: "selecttree",
  props: {
    items: Array,
    value: Array,
    label: { type: String, default: "Categories" },
    select_type: { type: String, default: "independent" },
    item_key: { type: String, default: "id" },
    item_text: { type: String, default: "label" },
    item_disabled: { type: String, default: "isDisabled" },
    select_item_color: { type: String, default: "red" },
    deletable_chips: { type: Boolean, default: true },
    item_color: { type: String, default: "color" },
    nbr_chips: { type: Number, default: 2 },
    clearable: { type: Boolean, default: false },
    oblig: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    selectable: { type: Boolean, default: true },
    closeoncontentclick: { type: Boolean, default: false },
    open_all: { type: Boolean, default: false },
    parent: { type: String, default: null },
  },
  components: {},

  data: () => ({
    catpicker1: false,
    open: [],
    opened: [],
    selected_tree: [],
    selected_items: [],
  }),

  computed: {
    selected_items2: {
      get() {
        let a = [];
        if (this.value)
          this.value.forEach((element) => {
            a.push(this.items.find((elm) => elm.id == element));
          });
        return a;
      },
      set(val) {
        let selected_items = [];
        val.sort(compare);
        val.forEach((element) => {
          selected_items.push(element.id);
        });
        this.$emit("input", selected_items);
      },
    },
    treelist() {
      return list_to_tree(this.items, this.parent);
    },
  },
  watch: {},
  created() {},

  mounted() {
    this.selected_items = this.value;
    if (this.value)
      this.value.forEach((element) => {
        let i = this.items.findIndex((elm) => elm.id == element);

        if (i >= 0) this.selected_tree.push(this.items[i]);
      });
  },

  methods: {
    s_change() {
      this.$emit("change", this.selected_items);
    },
    getActiveValue(value) {
      this.$emit("change", value);
      this.selected_items = [];
      value.forEach((element) => {
        this.selected_items.push(element.id);
      });
      this.$emit("input", this.selected_items);
    },
    getSelectedValue() {
      this.selected_items = [];
      this.selected_tree.forEach((element) => {
        this.selected_items.push(element.id);
      });
      this.$emit("input", this.selected_items);
    },
    delchips(item) {
      this.selected_items.remove(item.id);
      this.selected_tree.remove(item);
      this.$emit("input", this.selected_items);
    },
    onOpen(items) {
      var onlyInA = items.filter(comparer(this.opened));
      var onlyInB = this.opened.filter(comparer(items));

      let newnode = onlyInA.concat(onlyInB);
      if (newnode.length > 0) {
        let i = items.findIndex(
          (elm) =>
            elm.id != newnode[0].id && elm.fk_parent == newnode[0].fk_parent
        );
        if (i >= 0) items.splice(i, 1);
        this.opened = [];
        this.open.forEach((element) => {
          this.opened.push(element);
        });
        this.open = items;
      }
    },
    catchange() {},
  },
};
</script>

<style lang="scss">
//.select {
//  max-width: 1000px;
//  max-height: 150px;
//  font-size: 14px;
//}
.v-treeview-node__label {
  overflow: initial;
  text-overflow: initial;
}

.v-navigation-drawer__content {
  overflow-x: auto;
}

.v-treeview {
  width: max-content;
}
</style>
